import React from 'react';

//new component
const AdmissionForm = React.lazy(
  () => import('../components/admission-form/1')
);
const MainHome = React.lazy(() => import('../components/bg/1'));
const CurrentIssue = React.lazy(() => import('../components/current-issue'));
const Loader22 = React.lazy(() => import('./../components/loader/22'));
const DetailPage = React.lazy(() => import('./content-block/detail-page/1'));
const DavHeader = React.lazy(
  () => import('./../components/dav-website/dav-header')
);
const DavSlider = React.lazy(
  () => import('./../components/dav-website/dav-slider')
);
const DavCourse2 = React.lazy(
  () => import('../components/dav-website/dav-course/2')
);
const NoticeEventContentBlock = React.lazy(
  () => import('../components/dav-website/notice-event-content-block')
);
const DavNews = React.lazy(() => import('../components/dav-website/dav-news'));
const DavAward = React.lazy(
  () => import('../components/dav-website/dav-award')
);
const DavCounter = React.lazy(
  () => import('../components/dav-website/dav-counter')
);
const DavTestimonial = React.lazy(
  () => import('../components/dav-website/dav-testimonial')
);
const DavUsefullink2 = React.lazy(
  () => import('../components/dav-website/dav-usefullink/2')
);
const DavGallery = React.lazy(
  () => import('../components/dav-website/dav-gallery')
);
const DavFooter = React.lazy(
  () => import('../components/dav-website/dav-footer')
);
const MillsberryClzMobileMenu = React.lazy(
  () => import('./../components/millsberry-college/millsberry-clz-mobile-menu')
);
const InstaPage = React.lazy(() => import('./../components/insta-page'));
const MillsberryClzHeader = React.lazy(
  () => import('../components/millsberry-college/millsberry-clz-header')
);
const MillsberryClzSlider = React.lazy(
  () => import('../components/millsberry-college/millsberry-clz-slider/1')
);
const MillsberryClzSlider2 = React.lazy(
  () => import('../components/millsberry-college/millsberry-clz-slider/2')
);
const MillsberryClzNoticeEventsBlock = React.lazy(
  () => import('../components/millsberry-college/millsberryclz-notice-events')
);
const MillsberryClzAbout = React.lazy(
  () => import('../components/millsberry-college/millsberry-clz-about/1')
);
const MillsberryClzEvent = React.lazy(
  () => import('../components/millsberry-college/millsberry-clz-event')
);
const MillsberryClzNews = React.lazy(
  () => import('../components/millsberry-college/millsberry-clz-news')
);
const MillsberryClzApplyBanner = React.lazy(
  () => import('../components/millsberry-college/millsberry-clz-apply-banner')
);
const MillsberryClzCourse = React.lazy(
  () => import('../components/millsberry-college/millsberry-clz-course')
);
const MillsberryClzTestimonial = React.lazy(
  () => import('../components/millsberry-college/millsberry-clz-testimonial/1')
);
const MillsberryClzTestimonial2 = React.lazy(
  () => import('../components/millsberry-college/millsberry-clz-testimonial/2')
);
const MillsberryClzTestimonial3 = React.lazy(
  () => import('../components/millsberry-college/millsberry-clz-testimonial/3')
);
const MillsberryClzBlogFbYoutubeBlock = React.lazy(
  () =>
    import(
      '../components/millsberry-college/millsberry-clz-blog-fb-youtube-block'
    )
);
const MillsberryClzVideoBlock = React.lazy(
  () => import('../components/millsberry-college/millsberry-clz-video-block')
);
const MillsberryClzFooter = React.lazy(
  () => import('../components/millsberry-college/millsberry-clz-footer')
);
const MillsberryHeader = React.lazy(
  () => import('components/millsberry-website/millsberry-header/1')
);
const MillsberryHeader2 = React.lazy(
  () => import('components/millsberry-website/millsberry-header/2')
);
const MillsberrySlider = React.lazy(
  () => import('components/millsberry-website/millsberry-slider/1')
);
const MillsberrySlider2 = React.lazy(
  () => import('components/millsberry-website/millsberry-slider/2')
);
const MillsberryApplyBanner = React.lazy(
  () => import('components/millsberry-website/millsberry-apply-banner')
);
const MillsBerryAbout = React.lazy(
  () => import('components/millsberry-website/millsberry-about')
);
const MillsBerryCourse = React.lazy(
  () => import('components/millsberry-website/millsberry-course')
);
const NewsEventContentHolder = React.lazy(
  () => import('components/millsberry-website/news-event-content-block')
);
const MillsBerryTestimonial = React.lazy(
  () => import('components/millsberry-website/millsberry-testimonial')
);
const MillsberryGlobeMap = React.lazy(
  () => import('components/millsberry-website/millsberry-globe-map')
);
const MillsBerryBlog = React.lazy(
  () => import('components/millsberry-website/millsberry-blog')
);
const MillsberryFacilities = React.lazy(
  () => import('components/millsberry-website/millsberry-facilities')
);
const MillsberryCounter = React.lazy(
  () => import('components/millsberry-website/millsberry-counter')
);
const MillsberryFooter = React.lazy(
  () => import('components/millsberry-website/millsberry-footer')
);
const ApplyBanner2 = React.lazy(() => import('components/apply-banner/2'));
const ApplyBanner3 = React.lazy(() => import('components/apply-banner/3'));
const ApplyBanner4 = React.lazy(() => import('components/apply-banner/4'));
const ApplyBanner5 = React.lazy(() => import('components/apply-banner/5'));
const ApplyBanner6 = React.lazy(() => import('components/apply-banner/6'));
const DownloadOne = React.lazy(() => import('./download/1'));
const HeaderContentBlock = React.lazy(
  () => import('./../components/header-content-block/1')
);
const Header4 = React.lazy(() => import('./content-block/../header/4'));
const rpsharmaHeaderBg = React.lazy(
  () => import('components/rpsharma-website/rpsharma-header')
);
const Header7 = React.lazy(() => import('./content-block/../header/7'));
const Header8 = React.lazy(() => import('./content-block/../header/8'));
const Header9 = React.lazy(() => import('./content-block/../header/9'));
const Header10 = React.lazy(() => import('./content-block/../header/10'));
const Header11 = React.lazy(() => import('./content-block/../header/11'));
const Header12 = React.lazy(() => import('./content-block/../header/12'));
const Header13 = React.lazy(() => import('./content-block/../header/13'));
const Header14 = React.lazy(() => import('./content-block/../header/14'));
const Header15 = React.lazy(() => import('./content-block/../header/15'));
const Header16 = React.lazy(() => import('./content-block/../header/16'));
const Header17 = React.lazy(() => import('./content-block/../header/17'));
const Header18 = React.lazy(() => import('./content-block/../header/18'));
const Header19 = React.lazy(() => import('./content-block/../header/19'));
const Header20 = React.lazy(() => import('./content-block/../header/20'));
const Header21 = React.lazy(() => import('./content-block/../header/21'));
const Header22 = React.lazy(() => import('./content-block/../header/22'));
const Header23 = React.lazy(() => import('./content-block/../header/23'));
const Header24 = React.lazy(() => import('./content-block/../header/24'));
const MobileMenu4 = React.lazy(
  () => import('./content-block/../header/mobile-menu/4')
);
const HomeSlider2 = React.lazy(() => import('./../components/slider/2'));
const HomeSlider5 = React.lazy(() => import('./content-block/home-slider/5'));
const HomeSlider6 = React.lazy(() => import('./content-block/home-slider/6'));
const HomeSlider7 = React.lazy(() => import('./content-block/home-slider/7'));
const HomeSlider8 = React.lazy(() => import('./content-block/home-slider/8'));
const HomeSlider9 = React.lazy(() => import('./content-block/home-slider/9'));
const HomeSlider10 = React.lazy(() => import('./content-block/home-slider/10'));
const HomeSlider11 = React.lazy(() => import('./content-block/home-slider/11'));
const HomeSlider12 = React.lazy(() => import('./content-block/home-slider/12'));
const HomeSlider13 = React.lazy(() => import('./content-block/home-slider/13'));
const HomeSlider14 = React.lazy(() => import('./content-block/home-slider/14'));
const HomeSlider15 = React.lazy(() => import('./content-block/home-slider/15'));
const HomeSlider16 = React.lazy(() => import('./content-block/home-slider/16'));
const HomeSlider17 = React.lazy(() => import('./content-block/home-slider/17'));
const HomeSlider18 = React.lazy(() => import('./content-block/home-slider/18'));
const HomeSlider19 = React.lazy(() => import('./content-block/home-slider/19'));
const RpsharmaSlider = React.lazy(
  () => import('components/rpsharma-website/rpsharma-slider')
);
const ApplyButton = React.lazy(() => import('components/apply-button'));
const Notice4 = React.lazy(() => import('../components/notice/4'));
const Notice8 = React.lazy(() => import('../components/notice/8'));
const CardEffect = React.lazy(() => import('./../components/card-effect'));
const CourseBlock3 = React.lazy(() => import('./../components/course-block/3'));
const CourseBlock4 = React.lazy(() => import('./../components/course-block/4'));
const CourseBlock6 = React.lazy(() => import('./../components/course-block/6'));
const CourseBlock7 = React.lazy(() => import('./../components/course-block/7'));
const CourseBlock8 = React.lazy(() => import('./../components/course-block/8'));
const CourseBlock11 = React.lazy(
  () => import('./../components/course-block/11')
);
const CourseBlock12 = React.lazy(
  () => import('./../components/course-block/12')
);
const CourseBlock13 = React.lazy(
  () => import('./../components/course-block/13')
);
const CourseBlock14 = React.lazy(
  () => import('./../components/course-block/14')
);
const CourseBlock15 = React.lazy(
  () => import('./../components/course-block/15')
);
const CourseBlock16 = React.lazy(
  () => import('./../components/course-block/16')
);
const CourseBlock17 = React.lazy(
  () => import('./../components/course-block/17')
);
const CourseBlock18 = React.lazy(
  () => import('./../components/course-block/18')
);
const CourseBlock19 = React.lazy(
  () => import('./../components/course-block/19')
);
const InfoSlider = React.lazy(
  () => import('./../components/course-block/1/Info-Slider')
);
const CareerVacancies2 = React.lazy(
  () => import('../components/career-vacancies/2')
);
const CareerVacancies3 = React.lazy(
  () => import('../components/career-vacancies/3')
);
const CareerVacancies5 = React.lazy(
  () => import('../components/career-vacancies/5')
);
const Message = React.lazy(() => import('../components/messages/1'));
const Message2 = React.lazy(() => import('../components/messages/2'));
const Message3 = React.lazy(() => import('../components/messages/3'));
const OurPartners = React.lazy(() => import('../components/our-partners/1'));
const OurPartners2 = React.lazy(() => import('../components/our-partners/2'));
const OurPartners3 = React.lazy(() => import('../components/our-partners/3'));
const OurPartners4 = React.lazy(() => import('../components/our-partners/4'));
const OurPartners5 = React.lazy(() => import('../components/our-partners/5'));
const OurPartners6 = React.lazy(() => import('../components/our-partners/6'));
const SxcWebsite = React.lazy(() => import('./../components/sxc-website'));
const SxcAbout = React.lazy(() => import('./../components/sxc-about'));
const SocialBlock = React.lazy(() => import('../components/social-block/1'));
const SocialBlock2 = React.lazy(() => import('../components/social-block/2'));
const SocialBlock3 = React.lazy(() => import('../components/social-block/3'));
const Loader16 = React.lazy(() => import('./../components/loader/16'));
const Loader17 = React.lazy(() => import('./../components/loader/17'));
const Loader18 = React.lazy(() => import('./../components/loader/18'));
const VideoBlock = React.lazy(() => import('./content-block/video-block/1'));
const VideoBlock2 = React.lazy(() => import('./content-block/video-block/2'));
const VideoSlider = React.lazy(() => import('./content-block/video-slider'));
const GalleryBlock = React.lazy(
  () => import('./../components/gallery-block/1')
);
const GalleryBlock2 = React.lazy(
  () => import('./../components/gallery-block/2')
);
const GalleryBlock3 = React.lazy(
  () => import('./../components/gallery-block/3')
);
const GalleryBlock4 = React.lazy(
  () => import('./../components/gallery-block/4')
);
const GalleryBlock5 = React.lazy(
  () => import('./../components/gallery-block/5')
);
const HomePage = React.lazy(() => import('./../components/home-page/1'));
const HomePage2 = React.lazy(() => import('./../components/home-page/2'));
const HomePage3 = React.lazy(() => import('./../components/home-page/3'));
const ContentBlock2 = React.lazy(
  () => import('./../components/content-block/2')
);
const ContentBlock3 = React.lazy(
  () => import('./../components/content-block/3')
);
const ContentBlock4 = React.lazy(
  () => import('./../components/content-block/4')
);
const ContentBlock5 = React.lazy(
  () => import('./../components/content-block/5')
);
const ContentBlock6 = React.lazy(
  () => import('./../components/content-block/6')
);
const ContentBlock7 = React.lazy(
  () => import('./../components/content-block/7')
);
const ContentBlock8 = React.lazy(
  () => import('./../components/content-block/8')
);
const ContentBlock9 = React.lazy(
  () => import('./../components/content-block/9')
);
const EventSection4 = React.lazy(
  () => import('./../components/evnent-section/4')
);
const EventSection7 = React.lazy(
  () => import('./../components/evnent-section/7')
);
const EventSection8 = React.lazy(
  () => import('./../components/evnent-section/8')
);
const EventSection9 = React.lazy(
  () => import('./../components/evnent-section/9')
);
const EventSection10 = React.lazy(
  () => import('./../components/evnent-section/10')
);
const EventSection13 = React.lazy(
  () => import('./../components/evnent-section/13')
);
const EventSection14 = React.lazy(
  () => import('./../components/evnent-section/14')
);
const EventSection15 = React.lazy(
  () => import('./../components/evnent-section/15')
);
const EventSection16 = React.lazy(
  () => import('./../components/evnent-section/16')
);
const EventSection17 = React.lazy(
  () => import('./../components/evnent-section/17')
);
const EventSection18 = React.lazy(
  () => import('./../components/evnent-section/18')
);
const NewsSlider1 = React.lazy(() => import('./content-block/news-slider/1'));
const NewsSection5 = React.lazy(() => import('components/news-sec/5'));
const NewsSection7 = React.lazy(() => import('components/news-sec/7'));
const NewsSection8 = React.lazy(() => import('components/news-sec/8'));
const NewsSection9 = React.lazy(() => import('components/news-sec/9'));
const NewsSection10 = React.lazy(() => import('components/news-sec/10'));
const NewsSection11 = React.lazy(() => import('components/news-sec/11'));
const NewsSection12 = React.lazy(() => import('components/news-sec/12'));
const TeamComponent = React.lazy(
  () => import('./../components/team-component/1')
);
const TeamComponent2 = React.lazy(
  () => import('./../components/team-component/2')
);
const TeamComponent3 = React.lazy(
  () => import('./../components/team-component/3')
);
const TeamComponent4 = React.lazy(
  () => import('./../components/team-component/4')
);
const Introduction = React.lazy(() => import('./../components/introduction/1'));
const Introduction2 = React.lazy(
  () => import('./../components/introduction/2')
);
const Introduction3 = React.lazy(
  () => import('./../components/introduction/3')
);
const Introduction4 = React.lazy(
  () => import('./../components/introduction/4')
);
const Introduction5 = React.lazy(
  () => import('./../components/introduction/5')
);
const Introduction6 = React.lazy(
  () => import('./../components/introduction/6')
);
const Introduction7 = React.lazy(
  () => import('./../components/introduction/7')
);
const Introduction8 = React.lazy(
  () => import('./../components/introduction/8')
);
const Introduction9 = React.lazy(
  () => import('./../components/introduction/9')
);
const Introduction10 = React.lazy(
  () => import('./../components/introduction/10')
);
const Introduction11 = React.lazy(
  () => import('./../components/introduction/11')
);
const Introduction12 = React.lazy(
  () => import('./../components/introduction/12')
);
const Introduction13 = React.lazy(
  () => import('./../components/introduction/13')
);
const Introduction14 = React.lazy(
  () => import('./../components/introduction/14')
);
const Introduction15 = React.lazy(
  () => import('./../components/introduction/15')
);
const Introduction16 = React.lazy(
  () => import('./../components/introduction/16')
);
const Introduction17 = React.lazy(
  () => import('./../components/introduction/17')
);
const Introduction18 = React.lazy(
  () => import('./../components/introduction/18')
);
const Introduction19 = React.lazy(
  () => import('./../components/introduction/19')
);
const Introduction20 = React.lazy(
  () => import('./../components/introduction/20')
);
const Introduction21 = React.lazy(
  () => import('./../components/introduction/21')
);
const Introduction22 = React.lazy(
  () => import('./../components/introduction/22')
);
const Introduction23 = React.lazy(
  () => import('./../components/introduction/23')
);
const Introduction24 = React.lazy(
  () => import('./../components/introduction/24')
);
const Introduction25 = React.lazy(
  () => import('./../components/introduction/25')
);
const Introduction26 = React.lazy(
  () => import('./../components/introduction/26')
);
const Introduction27 = React.lazy(
  () => import('./../components/introduction/27')
);
const Introduction29 = React.lazy(
  () => import('./../components/introduction/29')
);
const Introduction30 = React.lazy(
  () => import('./../components/introduction/30')
);
const Introduction31 = React.lazy(
  () => import('./../components/introduction/31')
);
const Introduction32 = React.lazy(
  () => import('./../components/introduction/32')
);
const Introduction33 = React.lazy(
  () => import('./../components/introduction/33')
);
const Introduction34 = React.lazy(
  () => import('./../components/introduction/34')
);
const Introduction35 = React.lazy(
  () => import('./../components/introduction/35')
);
const Introduction36 = React.lazy(
  () => import('./../components/introduction/36')
);
const Introduction37 = React.lazy(
  () => import('./../components/introduction/37')
);
const RpsharmaAbout = React.lazy(
  () => import('./../components/rpsharma-website/rpsharma-about')
);
const RpsharmaPortfolio = React.lazy(
  () => import('./../components/rpsharma-website/rpsharma-portpholio')
);
const RpsharmaSkills = React.lazy(
  () => import('./../components/rpsharma-website/rpsharma-skills')
);
const CounterComponent = React.lazy(
  () => import('./../components/counter-component/1')
);
const CounterComponent2 = React.lazy(
  () => import('./../components/counter-component/2')
);
const CounterComponent3 = React.lazy(
  () => import('./../components/counter-component/3')
);
const CounterComponent4 = React.lazy(
  () => import('./../components/counter-component/4')
);
const CounterComponent5 = React.lazy(
  () => import('./../components/counter-component/5')
);
const CounterComponent6 = React.lazy(
  () => import('./../components/counter-component/6')
);
const CounterComponent7 = React.lazy(
  () => import('./../components/counter-component/7')
);
const ScrollAnimation = React.lazy(
  () => import('./../components/scrool-animation')
);
const Blog1 = React.lazy(() => import('./../components/blog/1'));
const Blog3 = React.lazy(() => import('./../components/blog/3'));
const Blog4 = React.lazy(() => import('./../components/blog/4'));
const Blog5 = React.lazy(() => import('./../components/blog/5'));
const Blog6 = React.lazy(() => import('./../components/blog/6'));
const Blog7 = React.lazy(() => import('./../components/blog/7'));
const DnLoginPage = React.lazy(() => import('./content-block/dn-login-page'));
const AboutBlock2 = React.lazy(() => import('./content-block/about-block/2'));
const ServiceBlock = React.lazy(
  () => import('./content-block/service-block/1')
);
const ServiceBlock2 = React.lazy(
  () => import('./content-block/service-block/2')
);
const ServiceBlock3 = React.lazy(
  () => import('./content-block/service-block/3')
);
const ServiceBlock4 = React.lazy(
  () => import('./content-block/service-block/4')
);
const ServiceBlock5 = React.lazy(
  () => import('./content-block/service-block/5')
);
const ServiceBlock6 = React.lazy(
  () => import('./content-block/service-block/6')
);
const ServiceBlock7 = React.lazy(
  () => import('./content-block/service-block/7')
);
const ServiceBlock8 = React.lazy(
  () => import('./content-block/service-block/8')
);
const ServiceBlock9 = React.lazy(
  () => import('./content-block/service-block/9')
);
const ServiceBlock10 = React.lazy(
  () => import('./content-block/service-block/10')
);
const ServiceBlock11 = React.lazy(
  () => import('./content-block/service-block/11')
);
const ServiceBlock12 = React.lazy(
  () => import('./content-block/service-block/12')
);
const ServiceBlock13 = React.lazy(
  () => import('./content-block/service-block/13')
);
const ServiceBlock14 = React.lazy(
  () => import('./content-block/service-block/14')
);
const ServiceBlock15 = React.lazy(
  () => import('./content-block/service-block/15')
);
const ServiceBlock16 = React.lazy(
  () => import('./content-block/service-block/16')
);
const ServiceBlock17 = React.lazy(
  () => import('./content-block/service-block/17')
);
const ServiceBlock18 = React.lazy(
  () => import('./content-block/service-block/18')
);
const ServiceBlock19 = React.lazy(
  () => import('./content-block/service-block/19')
);
const ServiceBlock20 = React.lazy(
  () => import('./content-block/service-block/20')
);
const ServiceBlock21 = React.lazy(
  () => import('./content-block/service-block/21')
);
const ServiceBlock22 = React.lazy(
  () => import('./content-block/service-block/22')
);
const ServiceBlock23 = React.lazy(
  () => import('./content-block/service-block/23')
);
const ServiceBlock24 = React.lazy(
  () => import('./content-block/service-block/24')
);
const ServiceBlock25 = React.lazy(
  () => import('./content-block/service-block/25')
);
const ServiceBlock26 = React.lazy(
  () => import('./content-block/service-block/26')
);
const ServiceBlock27 = React.lazy(
  () => import('./content-block/service-block/27')
);
const ServiceBlock28 = React.lazy(
  () => import('./content-block/service-block/28')
);
const ServiceBlock29 = React.lazy(
  () => import('./content-block/service-block/29')
);
const ServiceBlock30 = React.lazy(
  () => import('./content-block/service-block/30')
);
const ServiceBlock31 = React.lazy(
  () => import('./content-block/service-block/31')
);
const ServiceBlock32 = React.lazy(
  () => import('./content-block/service-block/32')
);
const ServiceBlock33 = React.lazy(
  () => import('./content-block/service-block/33')
);
const Faq = React.lazy(() => import('./content-block/faq'));
const SimpleBgComponent = React.lazy(
  () => import('./../components/simple-background-component/1')
);
const SimpleBackground = React.lazy(
  () => import('./content-block/simple-background/1')
);
const TestimonialSlider3 = React.lazy(
  () => import('./content-block/testimonial-slider/3')
);
const TestimonialSlider4 = React.lazy(
  () => import('./content-block/testimonial-slider/4')
);
const TestimonialSlider5 = React.lazy(
  () => import('./content-block/testimonial-slider/5')
);
const TestimonialSlider6 = React.lazy(
  () => import('./content-block/testimonial-slider/6')
);
const TestimonialSlider7 = React.lazy(
  () => import('./content-block/testimonial-slider/7')
);
const TestimonialSlider8 = React.lazy(
  () => import('./content-block/testimonial-slider/8')
);
const TestimonialSlider9 = React.lazy(
  () => import('./content-block/testimonial-slider/9')
);
const TestimonialSlider10 = React.lazy(
  () => import('./content-block/testimonial-slider/10')
);
const TestimonialSlider11 = React.lazy(
  () => import('./content-block/testimonial-slider/11')
);
const TestimonialSlider12 = React.lazy(
  () => import('./content-block/testimonial-slider/12')
);
const TestimonialSlider13 = React.lazy(
  () => import('./content-block/testimonial-slider/13')
);
const TestimonialSlider14 = React.lazy(
  () => import('./content-block/testimonial-slider/14')
);
const TestimonialSlider15 = React.lazy(
  () => import('./content-block/testimonial-slider/15')
);
const TestimonialSlider16 = React.lazy(
  () => import('./content-block/testimonial-slider/16')
);
const TestimonialSlider17 = React.lazy(
  () => import('./content-block/testimonial-slider/17')
);
const TestimonialSlider18 = React.lazy(
  () => import('./content-block/testimonial-slider/18')
);
const TestimonialSlider19 = React.lazy(
  () => import('./content-block/testimonial-slider/19')
);
const TestimonialSlider20 = React.lazy(
  () => import('./content-block/testimonial-slider/20')
);
const TestimonialSlider21 = React.lazy(
  () => import('./content-block/testimonial-slider/21')
);
const BoardMessage = React.lazy(() => import('../components/board-message/1'));
const BoardMessage2 = React.lazy(() => import('../components/board-message/2'));
const RpsharmaTestimonial = React.lazy(
  () => import('components/rpsharma-website/rpsharma-testimonial')
);
const RpsharmaBlog = React.lazy(
  () => import('components/rpsharma-website/rpsharma-blog')
);
const SocialBlock1 = React.lazy(() => import('./content-block/social-block/1'));
const AboutBlock1 = React.lazy(() => import('./content-block/about-block/1'));
const Program2 = React.lazy(() => import('./content-block/course-slider/2'));
const SubscribeBlock1 = React.lazy(
  () => import('./content-block/subscribe-block/1')
);
const Contact2 = React.lazy(() => import('./content-block/../contact/2'));
const Contact3 = React.lazy(() => import('./content-block/../contact/3'));
const Contact4 = React.lazy(() => import('./content-block/../contact/4'));
const Download1 = React.lazy(() => import('./content-block/download-block/1'));
const CardBlock1 = React.lazy(() => import('./content-block/card-block/1'));
const WhyChoose = React.lazy(() => import('./content-block/portpholio/1'));
const WhyChoose2 = React.lazy(() => import('components/why-choose/2'));
const Footer2 = React.lazy(() => import('./content-block/../footer/2'));
const Footer3 = React.lazy(() => import('./content-block/../footer/3'));
const Footer5 = React.lazy(() => import('./content-block/../footer/5'));
const Footer6 = React.lazy(() => import('./content-block/../footer/6'));
const Footer7 = React.lazy(() => import('./content-block/../footer/7'));
const Footer8 = React.lazy(() => import('./content-block/../footer/8'));
const Footer9 = React.lazy(() => import('./content-block/../footer/9'));
const Footer10 = React.lazy(() => import('./content-block/../footer/10'));
const Footer11 = React.lazy(() => import('./content-block/../footer/11'));
const Footer12 = React.lazy(() => import('./content-block/../footer/12'));
const Footer13 = React.lazy(() => import('./content-block/../footer/13'));
const Footer14 = React.lazy(() => import('./content-block/../footer/14'));
const Footer15 = React.lazy(() => import('./content-block/../footer/15'));
const Footer16 = React.lazy(() => import('./content-block/../footer/16'));
const Footer17 = React.lazy(() => import('./content-block/../footer/17'));
const Footer18 = React.lazy(() => import('./content-block/../footer/18'));
const Footer19 = React.lazy(() => import('./content-block/../footer/19'));
const Footer20 = React.lazy(() => import('./content-block/../footer/20'));
const Footer21 = React.lazy(() => import('./content-block/../footer/21'));
const Footer22 = React.lazy(() => import('./content-block/../footer/22'));
const RpsharmaFooter = React.lazy(
  () => import('components/rpsharma-website/rpsharma-footer')
);
const PopupModal1 = React.lazy(() => import('./content-block/popup-modal/1'));

const sandip = {
  AdmissionForm,
  MainHome,
  CurrentIssue,
  Loader22,
  DetailPage,
  DavHeader,
  DavSlider,
  DavCourse2,
  NoticeEventContentBlock,
  DavNews,
  DavAward,
  DavCounter,
  DavTestimonial,
  DavUsefullink2,
  DavGallery,
  DavFooter,
  InstaPage,
  MillsberryClzMobileMenu,
  MillsberryClzHeader,
  MillsberryClzSlider,
  MillsberryClzSlider2,
  MillsberryClzNoticeEventsBlock,
  MillsberryClzAbout,
  MillsberryClzEvent,
  MillsberryClzNews,
  MillsberryClzApplyBanner,
  MillsberryClzCourse,
  MillsberryClzTestimonial,
  MillsberryClzTestimonial2,
  MillsberryClzTestimonial3,
  MillsberryClzBlogFbYoutubeBlock,
  MillsberryClzVideoBlock,
  MillsberryClzFooter,
  MillsberryHeader,
  MillsberryHeader2,
  MillsberrySlider,
  MillsberrySlider2,
  MillsberryApplyBanner,
  MillsBerryAbout,
  MillsBerryCourse,
  NewsEventContentHolder,
  MillsBerryTestimonial,
  MillsberryGlobeMap,
  MillsBerryBlog,
  MillsberryFacilities,
  MillsberryCounter,
  MillsberryFooter,
  ApplyBanner2,
  ApplyBanner3,
  ApplyBanner4,
  ApplyBanner5,
  ApplyBanner6,
  DownloadOne,
  HeaderContentBlock,
  rpsharmaHeaderBg,
  Header4,
  Header7,
  Header8,
  Header9,
  Header10,
  Header11,
  Header12,
  Header13,
  Header14,
  Header15,
  Header16,
  Header17,
  Header18,
  Header19,
  Header20,
  Header21,
  Header22,
  Header23,
  Header24,
  MobileMenu4,
  HomeSlider2,
  HomeSlider5,
  HomeSlider6,
  HomeSlider7,
  HomeSlider8,
  HomeSlider9,
  HomeSlider10,
  HomeSlider11,
  HomeSlider12,
  HomeSlider13,
  HomeSlider14,
  HomeSlider15,
  HomeSlider16,
  HomeSlider17,
  HomeSlider18,
  HomeSlider19,
  RpsharmaSlider,
  ApplyButton,
  Notice4,
  Notice8,
  CardEffect,
  CourseBlock3,
  CourseBlock4,
  CourseBlock6,
  CourseBlock7,
  CourseBlock8,
  CourseBlock11,
  CourseBlock12,
  CourseBlock13,
  CourseBlock14,
  CourseBlock15,
  CourseBlock16,
  CourseBlock17,
  CourseBlock18,
  CourseBlock19,
  InfoSlider,
  CareerVacancies2,
  CareerVacancies3,
  CareerVacancies5,
  Message,
  Message2,
  Message3,
  OurPartners,
  OurPartners2,
  OurPartners3,
  OurPartners4,
  OurPartners5,
  OurPartners6,
  SxcWebsite,
  SxcAbout,
  SocialBlock,
  SocialBlock2,
  SocialBlock3,
  Loader16,
  Loader17,
  Loader18,
  VideoBlock,
  VideoBlock2,
  VideoSlider,
  GalleryBlock,
  GalleryBlock2,
  GalleryBlock3,
  GalleryBlock4,
  GalleryBlock5,
  HomePage,
  HomePage2,
  HomePage3,
  ContentBlock2,
  ContentBlock3,
  ContentBlock4,
  ContentBlock5,
  ContentBlock6,
  ContentBlock7,
  ContentBlock8,
  ContentBlock9,
  EventSection4,
  EventSection7,
  EventSection8,
  EventSection9,
  EventSection10,
  EventSection13,
  EventSection14,
  EventSection15,
  EventSection16,
  EventSection17,
  EventSection18,
  NewsSlider1,
  NewsSection5,
  NewsSection7,
  NewsSection8,
  NewsSection9,
  NewsSection10,
  NewsSection11,
  NewsSection12,
  TeamComponent,
  TeamComponent2,
  TeamComponent3,
  TeamComponent4,
  Introduction,
  Introduction2,
  Introduction3,
  Introduction4,
  Introduction5,
  Introduction6,
  Introduction7,
  Introduction8,
  Introduction9,
  Introduction10,
  Introduction11,
  Introduction12,
  Introduction13,
  Introduction14,
  Introduction15,
  Introduction16,
  Introduction17,
  Introduction18,
  Introduction19,
  Introduction20,
  Introduction21,
  Introduction22,
  Introduction23,
  Introduction24,
  Introduction25,
  Introduction26,
  Introduction27,
  Introduction29,
  Introduction30,
  Introduction31,
  Introduction32,
  Introduction33,
  Introduction34,
  Introduction35,
  Introduction36,
  Introduction37,
  RpsharmaAbout,
  RpsharmaPortfolio,
  RpsharmaSkills,
  CounterComponent,
  CounterComponent2,
  CounterComponent3,
  CounterComponent4,
  CounterComponent5,
  CounterComponent6,
  CounterComponent7,
  ScrollAnimation,
  Blog1,
  Blog3,
  Blog4,
  Blog5,
  Blog6,
  Blog7,
  DnLoginPage,
  AboutBlock2,
  ServiceBlock,
  ServiceBlock2,
  ServiceBlock3,
  ServiceBlock4,
  ServiceBlock5,
  ServiceBlock6,
  ServiceBlock7,
  ServiceBlock8,
  ServiceBlock9,
  ServiceBlock10,
  ServiceBlock11,
  ServiceBlock12,
  ServiceBlock13,
  ServiceBlock14,
  ServiceBlock15,
  ServiceBlock16,
  ServiceBlock17,
  ServiceBlock18,
  ServiceBlock19,
  ServiceBlock20,
  ServiceBlock21,
  ServiceBlock22,
  ServiceBlock23,
  ServiceBlock24,
  ServiceBlock25,
  ServiceBlock26,
  ServiceBlock27,
  ServiceBlock28,
  ServiceBlock29,
  ServiceBlock30,
  ServiceBlock31,
  ServiceBlock32,
  ServiceBlock33,
  Faq,
  SimpleBgComponent,
  SimpleBackground,
  TestimonialSlider3,
  TestimonialSlider4,
  TestimonialSlider5,
  TestimonialSlider6,
  TestimonialSlider7,
  TestimonialSlider8,
  TestimonialSlider9,
  TestimonialSlider10,
  TestimonialSlider11,
  TestimonialSlider12,
  TestimonialSlider13,
  TestimonialSlider14,
  TestimonialSlider15,
  TestimonialSlider16,
  TestimonialSlider17,
  TestimonialSlider18,
  TestimonialSlider19,
  TestimonialSlider20,
  TestimonialSlider21,
  BoardMessage,
  BoardMessage2,
  RpsharmaTestimonial,
  RpsharmaBlog,
  SocialBlock1,
  AboutBlock1,
  Program2,
  SubscribeBlock1,
  Contact2,
  Contact3,
  Contact4,
  Download1,
  CardBlock1,
  WhyChoose,
  WhyChoose2,
  Footer2,
  Footer3,
  Footer5,
  Footer6,
  Footer7,
  Footer8,
  Footer9,
  Footer10,
  Footer11,
  Footer12,
  Footer13,
  Footer14,
  Footer15,
  Footer16,
  Footer17,
  Footer18,
  Footer19,
  Footer20,
  Footer21,
  Footer22,
  RpsharmaFooter,
  PopupModal1,
};

export default sandip;
